// AIzaSyCwCVVkXq2xg5P7xmeqalNOmVJT1R95iXw

document.addEventListener('DOMContentLoaded', () => {
    const burger = document.querySelector('#burger');
    const closer = document.querySelector('.closer');
    if (burger !== null) {
        burger.addEventListener('click', event => {
            document.getElementById("sidenav").style.width = "250px";
        });
        closer.addEventListener('click', event => {
            document.getElementById("sidenav").style.width = "0px";
        });


        var scroller = document.getElementById("top-scroller");
        scroller.addEventListener('click', function(event) {
            window.scrollTo({top: 0, behavior: 'smooth'});
        });

        var checkScroll = function() {
            var y = window.scrollY;
            if (y >= 800) {
                scroller.classList.remove('hidden');
                console.log(y);
            } else {
                scroller.classList.add('hidden');
                console.log(y);
            }
        };

        window.addEventListener("scroll", checkScroll);
    }

    document.querySelectorAll('[data-autoresize]').forEach(function (element) {
        element.style.boxSizing = 'border-box';
        element.style.height = 'auto';
        let offset = element.offsetHeight - element.clientHeight;
        element.style.height = element.scrollHeight + offset + 'px';
        element.addEventListener('input', function (event) {
            event.target.style.height = 'auto';
            event.target.style.height = event.target.scrollHeight + offset + 'px';
        });
        element.removeAttribute('data-autoresize');
    });

    document.addEventListener('click', function(event) {
        let brg = document.querySelector('#burger');
        if (brg !== null) {
            let target = event.target;
            let nav = document.getElementById("sidenav")
            console.log(target)
            if (target.closest('#sidenav') === null && target.closest('.top') === null && nav.style.width === '250px') {
                nav.style.width = "0px";
            }
        }
    })

    document.querySelectorAll('.image-block').forEach(function (element) {
        element.addEventListener('click', function(event) {
            window.location.href = `/${element.dataset.url}`
        })
        element.style.height = element.offsetWidth + 'px'

    })



});